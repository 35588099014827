import React, { useState } from "react"
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Row,
    Col
} from "reactstrap";
import MembersService from "services/members.service"

import moment from 'moment'

function MemberDetail(props) {
    const data = props.data
    const [id, setId] = useState(data.id || '')
    const [role, setRole] = useState(data.role || '')
    const [account, setAccount] = useState(data.username || '')
    const [email, setEmail] = useState(data.email || '')
    const [fullName, setFullName] = useState(data.fullname || '')
    const [password, setPassword] = useState('')
    const [createdAt, setCreatedAt] = useState(moment(data.createdAt).format('DD/MM/yyyy') || '')
    const [updatedAt, setUpdatedAt] = useState(moment(data.updatedAt).format('DD/MM/yyyy') || '')
    const [passwordEncryption, setPasswordEncryption] = useState(data.password || '')

    function deleteMember() {
        if (role == 'ADMIN') {
            Swal.fire({
                icon: 'error',
                title: 'Thông báo',
                text: 'Bạn không thể xóa tài khoản admin',
            })
        } else {
            Swal.fire({
                title: 'Xác nhận',
                text: 'Bạn có chắc chắn muốn xóa tài khoản này ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
            }).then((result) => {
                if (result.value) {
                    MembersService.deleteMemberByMemberId(id)
                        .then(response => {
                            Swal.fire({
                                icon: 'success',
                                title: 'Thành công',
                                text: response.data.message,
                            }).then(res => {
                                window.location.reload();
                            })
                        }, error => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Lỗi',
                                text: error.message,
                            })
                        })
                };
            })
        }
    }

    function updateMember() {
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn thay đổi thông tin tài khoản này ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                MembersService.updateMemberByMemberId(id, memberInformationWillUpdate())
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Thành công',
                            text: response.data.message,
                        }).then(res => {
                            window.location.reload();
                        })
                    }, error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Lỗi',
                            text: error.message,
                        })
                    })
            };
        })
    }

    function memberInformationWillUpdate() {
        return {
            "username": account,
            "password": changePassword(password),
            "email": email,
            "fullname": fullName,
        }
    }

    function changePassword(pass){      
        return pass == '' ? passwordEncryption : pass
    }

    return (
        <Row>
            <Col md="12">
                <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                    <CardHeader>
                        <CardTitle tag="h4">
                            Chi tiết tài khoản
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="id">Mã người dùng</Label>
                                    <Input
                                        value={id}
                                        id="id"
                                        disabled={true}
                                        onChange={(e) => setId(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="role">Quyền</Label>
                                    <Input
                                        value={role}
                                        id="role"
                                        disabled={true}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="account">Tài khoản</Label>
                                    <Input
                                        value={account}
                                        id="account"
                                        onChange={(e) => setAccount(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Mật khẩu mới</Label>
                                    <Input
                                        type="password"
                                        value={password}
                                        id="password"
                                        placeholder="Để trống nếu không thay đổi"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        value={email}
                                        id="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fullname">Họ và tên</Label>
                                    <Input
                                        value={fullName}
                                        id="fullname"
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="createdAt">Thời gian tạo</Label>
                                    <Input
                                        value={createdAt}
                                        id="createdAt"
                                        disabled={true}
                                        onChange={(e) => setCreatedAt(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="updatedAt">Cập nhật lần cuối</Label>
                                    <Input
                                        value={updatedAt}
                                        id="updatedAt"
                                        disabled={true}
                                        onChange={(e) => setUpdatedAt(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-right">
                                <Button color="danger" style={{ width: "120px", height: "38px", marginRight: "10px" }} onClick={() => deleteMember()}>
                                    Xóa
                                </Button>
                                <Button color="primary" style={{ width: "120px", height: "38px" }} onClick={() => updateMember()}>
                                    Cập nhật
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default MemberDetail