import React, { useState } from "react"
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Row,
    Col
} from "reactstrap";
import MembersService from "services/members.service"

function CreateMenber() {
    const [account, setAccount] = useState('')
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [password, setPassword] = useState('')

    function createMember() {
        if (!account || !password || !email || !fullName) {
            Swal.fire({
                icon: 'error',
                title: 'Lỗi',
                text: 'Vui lòng điền đầy đủ thông tin',
            });
            return;
        }

        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có muốn thêm tài khoản này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                MembersService.createMember(memberInformationWillCreate())
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Thành công',
                            text: response.data.message || "Tạo tài khoản thành công",
                            showConfirmButton: true,
                        }).then(res => {
                            window.location.reload();
                        });
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Lỗi',
                            text: error.response?.data?.message || "Có lỗi xảy ra khi tạo tài khoản",
                        });
                    });
            }
        });
    }

    function memberInformationWillCreate() {
        return {
            "username": account,
            "password": password,
            "email": email,
            "fullname": fullName,
        }
    }

    return (
        <Row>
            <Col md="12">
                <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                    <CardHeader>
                        <CardTitle tag="h4">Thêm tài khoản mới</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="account">Tài khoản</Label>
                                    <Input
                                        value={account}
                                        id="account"
                                        placeholder="Nhập tên tài khoản"
                                        onChange={(e) => setAccount(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Mật khẩu</Label>
                                    <Input
                                        type="password"
                                        value={password}
                                        id="password"
                                        placeholder="Nhập mật khẩu"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        value={email}
                                        id="email"
                                        placeholder="Nhập email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fullname">Họ và tên</Label>
                                    <Input
                                        value={fullName}
                                        id="fullname"
                                        placeholder="Nhập họ và tên"
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-center">
                                <Button 
                                    color="primary" 
                                    style={{ width: "180px", height: "38px" }}
                                    onClick={() => createMember()}>
                                    Thêm tài khoản
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default CreateMenber