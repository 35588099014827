// src/services/dashboard.service.js

import axios from 'axios';
import authHeader from './auth-header';

// Định nghĩa URL của 2 server
const API1 = `https://api.dhbtools.com/server/v6/server-status`;
const API2 = `https://api2.dhbtools.com/server/v6/server-status`;

const SERVICE1 = `https://service.dhbtools.com/server/v6/server-status`;
const SERVICE2 = `https://service2.dhbtools.com/server/v6/server-status`;

const DOWNLOAD = `https://download.dhbtools.com/server/v6/server-status`;

class DashboardService {
    // Gọi API lấy trạng thái server API 1
    getServerAPI_1() {
        return axios.get(API1, { headers: authHeader() });
    }

    // Gọi API lấy trạng thái server API 2
    getServerAPI_2() {
        return axios.get(API2, { headers: authHeader() });
    }

    // Gọi API lấy trạng thái server SERVICE 1
    getServerService_1() {
        return axios.get(SERVICE1, { headers: authHeader() });
    }

    // Gọi API lấy trạng thái server SERVICE 2
    getServerService_2() {
        return axios.get(SERVICE2, { headers: authHeader() });
    }

    // Gọi API lấy trạng thái server DOWNLOAD
    getServerDownload() {
        return axios.get(DOWNLOAD, { headers: authHeader() });
    }

    // Hoặc nếu muốn gọi cả 2 server trong 1 hàm
    getAllServers() {
        return Promise.all([
            axios.get(API1, { headers: authHeader() }),
            axios.get(API2, { headers: authHeader() }),
            axios.get(SERVICE1, { headers: authHeader() }),
            axios.get(SERVICE2, { headers: authHeader() }),
            axios.get(DOWNLOAD, { headers: authHeader() })
        ]);
    }
}

export default new DashboardService();
