import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_MY_HOST}/admin/v6/language`;
const FORWARD_API_URL = `${process.env.REACT_APP_FORWARD_HOST}/admin/v6/language`;

class LanguageService {

    getLanguage(tool) {
        return axios.get(`${API_URL}?tool=${tool}`, { headers: authHeader() });
    }

    createLanguage(tool, key, vi, en) {
        return axios.post(`${API_URL}?tool=${tool}&key=${key}&vi=${vi}&en=${en}`, null, { headers: authHeader() });
    }

    createLanguageJson(tool, jsonData) {
        return axios.post(`${API_URL}-json?tool=${tool}`, jsonData, { 
            headers: {  
                ...authHeader(), 
                'Content-Type': 'application/json' 
            } 
        });
    }

    updateLanguage(tool, id, key, vi, en) {
        return axios.post(`${API_URL}-update?tool=${tool}&id=${id}&key=${key}&vi=${vi}&en=${en}`, null, { headers: authHeader() });
    }

    deleteLanguage(tool, id, key) {
        return axios.post(`${API_URL}-delete?tool=${tool}&id=${id}&key=${key}`, null, { headers: authHeader() });
    }

    commitLanguage(tool) {
        return axios
            .get(`${FORWARD_API_URL}/commit?tool=${tool}`, { headers: authHeader() })
            .then(forwardRes => {
                // Khi request forward thành công, gọi request tiếp theo
                return axios.get(`${API_URL}/commit?tool=${tool}`, { headers: authHeader() });
            });
    }
}

export default new LanguageService();
