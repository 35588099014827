import axios from 'axios';
import Deco from "security/deco"
import authHeader from './auth-header';

const fs = require('fs').promises;

const API_URL = `${process.env.REACT_APP_MY_HOST}/admin/v6`;
const FORWARD_API_URL = `${process.env.REACT_APP_FORWARD_HOST}/admin/v6`;

class UpdateService {

	getUpdate(tool) {
		return axios.get(`${API_URL}/update/${tool}`, { headers: authHeader() });
	}

	postUpdate(tool, version, log, selectedFile, onUploadProgressForward, onUploadProgressCurrent) {
		var formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("version", version);
		formData.append("log", log);

		// Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')))
		if (user && user.token) {
			const headers = {
				"Content-Type": "multipart/form-data",
				"Authorization": 'Bearer ' + user.token
			};

			const requestForward = axios({
				method: "post",
				url: `${FORWARD_API_URL}/update/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressForward
			});

			const requestCurrent = axios({
				method: "post",
				url: `${API_URL}/update/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressCurrent
			});

			return Promise.all([requestForward, requestCurrent]);
		}
	}

	putUpdate(id, tool, version, log, selectedFile) {
		if (selectedFile) {
			var fs = require('fs').promises;
			var formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("version", version);
			formData.append("log", log);

			//Authen
			const user = JSON.parse(Deco(localStorage.getItem('user')))
			if (user && user.token) {
				try {
					//Request về server còn lại để sửa file
					axios({
						method: "put",
						url: `${FORWARD_API_URL}/update/${tool}/${id}`,
						data: formData,
						headers: {
							"Content-Type": "multipart/form-data",
							"Authorization": 'Bearer ' + user.token
						}
					});

					return axios({
						method: "put",
						url: `${API_URL}/update/${tool}/${id}`,
						data: formData,
						headers: {
							"Content-Type": "multipart/form-data",
							"Authorization": 'Bearer ' + user.token
						}
					});

				} catch (error) {
					console.log(error)
				}
			}
		} else {
			var data = {
				method: "put",
				url: API_URL + tool + '/' + id,
				data: {
					version: version,
					log: log
				},
				headers: authHeader()
			}

			return axios(data);
		}
	}

	deleteUpdate(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${FORWARD_API_URL}/update/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL}/update/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}



	getPlugin(tool) {
		return axios.get(`${API_URL}/plugin/${tool}`, { headers: authHeader() });
	}

	postPlugin(tool, type, localPath, selectedFile, onUploadProgressForward, onUploadProgressCurrent) {
		let formData;
		let contentType;
		if (type === "Chọn từ server") {
			formData = {
				type: type,
				file: selectedFile,
				localPath: localPath
			};
			contentType = "application/json";
		} else {
			formData = new FormData();
			formData.append("type", type);
			formData.append("file", selectedFile);
			formData.append("localPath", localPath);
			contentType = "multipart/form-data";
		}

		// Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')));

		if (user && user.token) {
			const headers = {
				"Content-Type": contentType,
				"Authorization": 'Bearer ' + user.token
			};

			const requestForward = axios({
				method: "post",
				url: `${FORWARD_API_URL}/plugin/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressForward
			});

			const requestCurrent = axios({
				method: "post",
				url: `${API_URL}/plugin/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressCurrent
			});

			return Promise.all([requestForward, requestCurrent]);
		}
	}

	deletePlugin(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${FORWARD_API_URL}/plugin/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL}/plugin/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}




	getDll(tool) {
		return axios.get(`${API_URL}/dll/${tool}`, { headers: authHeader() });
	}

	async postDll(tool, type, localPath, selectedFile, onUploadProgressForward, onUploadProgressCurrent) {
		let formData;
		let contentType;

		if (type === "Chọn từ server") {
			formData = {
				type: type,
				file: selectedFile,
				localPath: localPath
			};
			contentType = "application/json";
		} else {
			formData = new FormData();
			formData.append("type", type);
			formData.append("file", selectedFile);
			formData.append("localPath", localPath);
			contentType = "multipart/form-data";
		}

		// Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')));
		if (user && user.token) {
			const headers = {
				"Content-Type": contentType,
				"Authorization": 'Bearer ' + user.token
			};

			const requestForward = axios({
				method: "post",
				url: `${FORWARD_API_URL}/dll/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressForward
			});

			const requestCurrent = axios({
				method: "post",
				url: `${API_URL}/dll/${tool}`,
				data: formData,
				headers: headers,
				onUploadProgress: onUploadProgressCurrent
			});

			return Promise.all([requestForward, requestCurrent]);
		}
	}

	deleteDll(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${FORWARD_API_URL}/dll/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL}/dll/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}



	// dev.dhbtools.com (explorer)
	getExplorerFiles(folder) {
		return axios.get(`${API_URL}/explorer?folder=${folder}`, { headers: authHeader() });
	}
}

export default new UpdateService();
