import React, { useState, useEffect } from 'react'
import {
    Table,
    Input,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Col,
    Row,
    Card,
    CardFooter,
    CardBody,
    Label,
    FormGroup,
    CardHeader,
    CardTitle
} from "reactstrap";
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import toolService from 'services/tool.service'
import updateService from 'services/update.service'


function Orders() {
    const [lstTool, setLstTool] = useState([])
    const [tool, setTool] = useState('dhbreupfacebook')
    const [toolName, setToolName] = useState('DHB Reup Facebook')
    const [update, setUpdate] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [id, setId] = useState('')
    const [version, setVersion] = useState('')
    const [log, setLog] = useState('')
    const [selectedFile, setSelectedFile] = useState('')



    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        toolService.getToolDatabase().then((result) => {
            setLstTool(result.data)

            loadUpdate(result.data[0].matool)
            setToolName(result.data[0].name)
        })
    }, [])

    function loadUpdate(tool) {
        setTool(tool)

        updateService.getUpdate(tool).then((result) => {
            console.log(result.data.data)
            setUpdate(result.data.data)
        })
    }

    function postUpdate() {
        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'Chưa chọn file'
            });
            return;
        }

        // Hiển thị Swal modal với 2 thanh progress đẹp, có % hiển thị
        Swal.fire({
            title: 'Đang tải lên bản cập nhập...',
            html: `
          <style>
            .progress-wrapper {
              position: relative;
              width: 100%;
              height: 25px;
              background-color: #eee;
              margin: 10px 0;
              border-radius: 5px;
            }
            .progress-bar {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              background-color: #28a745;
              text-align: center;
              color: #fff;
              line-height: 25px;
              width: 0%;
              transition: width 0.4s ease;
            }
          </style>
          <div>
            <div style="margin-top:10px;">Server VN (ViettelIDC)</div>
            <div class="progress-wrapper">
              <div id="progressBar1" class="progress-bar">0%</div>
            </div>
            <div style="margin-top:20px;">Server SG (Digital Ocean)</div>
            <div class="progress-wrapper">
              <div id="progressBar2" class="progress-bar">0%</div>
            </div>
          </div>
          `,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        updateService.postUpdate(
            tool,
            version,
            log,
            selectedFile,
            // Callback tiến trình upload tới Server VN
            (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const bar1 = Swal.getPopup().querySelector('#progressBar1');
                if (bar1) {
                    bar1.style.width = percentCompleted + '%';
                    bar1.textContent = percentCompleted + '%';
                }
            },
            // Callback tiến trình upload tới Server SG
            (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const bar2 = Swal.getPopup().querySelector('#progressBar2');
                if (bar2) {
                    bar2.style.width = percentCompleted + '%';
                    bar2.textContent = percentCompleted + '%';
                }
            }
        )
            .then((responses) => {
                const forwardResponse = responses[0];
                const currentResponse = responses[1];
                const success = forwardResponse.data.success && currentResponse.data.success;
                Swal.fire({
                    icon: success ? 'success' : 'error',
                    title: success ? 'Upload thành công' : 'Upload thất bại',
                    text: `Server VN: ${forwardResponse.data.success ? 'thành công' : 'thất bại'} \n\n Server SG: ${currentResponse.data.success ? 'thành công' : 'thất bại'}`,
                    showConfirmButton: true,
                    timer: 3000
                });
                if (success) {
                    updateService.getUpdate(tool).then(result => {
                        setUpdate(result.data.data);
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Upload thất bại',
                    text: error.message,
                });
            });
    }

    function putUpdate() {
        updateService.putUpdate(id, tool, version, log, selectedFile).then((result) => {
            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                showConfirmButton: true,
                timer: 1000
            }).then(res => {
                window.location.reload()
            })
        })
    }

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Phiên bản</th>
                        <th className="table-device-mobile">Tải lên lúc</th>
                        <th className="table-device-mobile">Tên tệp tin</th>
                        <th className="table-device-mobile">Thông tin cập nhập</th>
                        <th className='text-center'>Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function toAddNew() {
        var val = {}
        val.tool = tool
        // setDataProps(val)
        // setPage('detail')
    }

    function toDetailView(val) {
        setVersion(val.version)
        setLog(val.log)
        setId(val.id)
    }

    function loadFillData() {
        return update.map((data, key) => {
            return (
                <tr onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <td className="table-device-mobile">{data.version}</td>
                    <td className="table-device-mobile"> {moment(data.createdAt).format('DD/MM/yyyy HH:mm')} </td>
                    <td className="table-device-mobile">{data.url_v2}</td>
                    <td className="table-device-mobile" style={{ whiteSpace: "pre-line", lineHeight: "1.5" }} >{data.log}</td>

                    <td className='text-center'>
                        <Button style={{ marginLeft: "10px" }} type="button" className="btn btn-danger " onClick={() => {

                            Swal.fire({
                                title: 'Xác nhận xóa ?',
                                text: `Điều này sẽ xóa bản cập nhập ${data.version} phần mềm ${toolName}`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Có, xóa bản cập nhập!'
                            }).then((result) => {
                                if (result.isConfirmed) {

                                    updateService.deleteUpdate(data.tool, data.id, data.url_v2).then((result) => {
                                        if (result.data.success) {
                                            updateService.getUpdate(data.tool).then((result) => {
                                                setUpdate(result.data.data)
                                            })
                                        }

                                        Swal.fire({
                                            icon: result.data.success ? 'success' : 'error',
                                            title: result.data.message,
                                            showConfirmButton: true,
                                            timer: 1000
                                        })
                                    })
                                }
                            })
                        }}>Xóa</Button>
                    </td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            <Row>
                <Col md="9">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                        <CardHeader>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                <DropdownToggle className="btn-info" caret>{toolName}</DropdownToggle>
                                <DropdownMenu >
                                    {
                                        lstTool.map((data, key) => {
                                            return (
                                                <DropdownItem onClick={() => { loadUpdate(data.matool); setToolName(data.name) }} key={key}>{data.name}</DropdownItem>
                                            )
                                        })
                                    }

                                    {/* <DropdownItem onClick={() => { loadUpdate('dhbreupyoutube'); setToolName("DHB Reup Youtube") }} >Reup Youtube</DropdownItem>
                                    <DropdownItem onClick={() => { loadUpdate('dhbreupfacebook'); setToolName("DHB Reup Facebook") }} >Reup Facebook</DropdownItem>
                                    <DropdownItem onClick={() => { loadUpdate('dhbreuptiktok'); setToolName("DHB Reup TikTok") }} >Reup TikTok</DropdownItem>
                                    <DropdownItem onClick={() => { loadUpdate('dhbreupinstagram'); setToolName("DHB Reup Instagram") }} >Reup Instagram</DropdownItem>
                                    <DropdownItem onClick={() => { loadUpdate('dhbreuptwitter'); setToolName("DHB Reup Twitter") }} >Reup Twitter</DropdownItem>
                                    <DropdownItem onClick={() => { loadUpdate('dhbstreampro'); setToolName("DHB Stream Pro") }} >Stream Pro</DropdownItem> */}
                                    {/* <DropdownItem onClick={() => { loadUpdate('dhbstreamfacebook'); setToolName("DHB Stream Facebook") }} >Stream Facebook</DropdownItem> */}
                                    <DropdownItem onClick={() => { loadUpdate('dhbyoutubemanager'); setToolName("DHB Youtube Manager") }} >Youtube Manager</DropdownItem>
                                    {/* <DropdownItem onClick={() => { loadUpdate('dhbgologinmanager'); setToolName("DHB GoLogin Manager") }} >GoLogin Manager</DropdownItem> */}
                                    {/* <DropdownItem onClick={() => { loadUpdate('dhbrender'); setToolName("DHB Render") }} >DHB Render</DropdownItem> */}
                                    <DropdownItem onClick={() => { loadUpdate('appautodownload'); setToolName("Auto Download") }} >Auto Download</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardHeader>
                        <CardBody>
                            {table()}
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="3" md="4">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Thêm bảng cập nhập</Label>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label >Phiên bản</Label>
                                <Input
                                    value={version}
                                    id="version"
                                    onChange={(e) => { setVersion(e.target.value) }}
                                />

                                <Label style={{ marginTop: "1rem" }}>Thông tin cập nhập</Label>
                                <Input style={{ maxHeight: "100%" }}
                                    type="textarea"
                                    rows="5"
                                    value={log}
                                    id="log"
                                    onChange={(e) => { setLog(e.target.value) }}
                                />

                                <Label style={{ marginTop: "1rem" }}>Tệp tin</Label>
                                <Input
                                    type="file"
                                    id="file"
                                    onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                />
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <Button
                                disabled={!(version && log && selectedFile)}
                                style={{ width: "48%" }}
                                type="button"
                                className="btn btn-success"
                                onClick={postUpdate}
                            >
                                Thêm mới
                            </Button>


                            <Button
                                disabled={!(id && version && log)}
                                style={{ width: "48%", marginLeft: "4%" }}
                                type="button"
                                className="btn btn-warning"
                                onClick={putUpdate}
                            >
                                Cập nhập
                            </Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </div >
    )
}

export default Orders