import React, { useState, useEffect, useMemo } from 'react';
import {
	Table,
	Input,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	FormGroup,
	Label,
	Alert,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import languageService from 'services/language.service';

// Thêm style global cho LanguageTable
const tableRowStyle = {
	cursor: 'pointer', 
	transition: 'background-color 0.1s ease',
	userSelect: 'none', // Ngăn chặn việc bôi đen text khi click nhanh
};

const selectedRowStyle = {
	...tableRowStyle,
	backgroundColor: '#d3d3d3'
};

// Style cho bảng để tối ưu hiệu suất render
const tableStyle = {
	tableLayout: 'fixed', // Cố định layout của bảng
	borderCollapse: 'collapse',
	width: '100%',
};

// Style cho bảng
const cellStyle = {
	padding: '8px 12px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

// Style cho input trong table khi edit inline
const cellInputStyle = {
	width: '100%',
	padding: '6px',
	border: '1px solid #ccc',
	borderRadius: '4px',
	boxSizing: 'border-box'
};

// Style cho Alert notification
const notificationStyle = {
	position: 'fixed',
	bottom: '20px',
	right: '20px',
	zIndex: 1050,
	minWidth: '300px',
	boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
	animation: 'fadeIn 0.3s ease-in'
};

function Language() {
	// State cho tool
	const [tool, setTool] = useState('dhbreupyoutube');
	const [toolName, setToolName] = useState('DHB Reup Youtube');

	// Danh sách bản ghi ngôn ngữ và tìm kiếm
	const [languages, setLanguages] = useState([]);
	const [search, setSearch] = useState('');

	// Dropdown chọn tool
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleDropdown = () => setDropdownOpen(prev => !prev);

	// State cho form thêm/cập nhật
	const [formKey, setFormKey] = useState('');
	const [formVi, setFormVi] = useState('');
	const [formEn, setFormEn] = useState('');

	// State cho chuyển tab
	const [activeTab, setActiveTab] = useState('form');
	const [jsonInput, setJsonInput] = useState('');
	const [jsonError, setJsonError] = useState('');

	// Sử dụng mảng để hỗ trợ multi-select và lưu vị trí dòng cuối được chọn (để hỗ trợ Shift)
	const [selectedRecords, setSelectedRecords] = useState([]);
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

	// State cho edit inline
	const [editingRow, setEditingRow] = useState(null);
	const [editData, setEditData] = useState({ key: '', vi: '', en: '' });

	// State cho notification Alert
	const [notification, setNotification] = useState({
		visible: false,
		color: 'success',
		message: ''
	});

	// Load danh sách khi tool thay đổi
	useEffect(() => {
		loadLanguages(tool);
	}, [tool]);

	// Tự động ẩn thông báo sau 3 giây
	useEffect(() => {
		let timer;
		if (notification.visible) {
			timer = setTimeout(() => {
				setNotification(prev => ({ ...prev, visible: false }));
			}, 3000);
		}
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [notification.visible]);

	const loadLanguages = (toolParam) => {
		languageService.getLanguage(toolParam)
			.then(res => {
				setLanguages(res.data);
				// Xóa lựa chọn khi load danh sách mới
				setSelectedRecords([]);
				setLastSelectedIndex(null);
				setEditingRow(null);
			})
			.catch(err => {
				showNotification('danger', `Lỗi tải dữ liệu: ${err.message}`);
				console.error(err);
			});
	};

	// Hiển thị thông báo
	const showNotification = (color, message) => {
		setNotification({
			visible: true,
			color,
			message
		});
	};

	// Đóng thông báo
	const onDismissNotification = () => {
		setNotification(prev => ({ ...prev, visible: false }));
	};

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};

	// Sử dụng useMemo để tránh tính toán lại mỗi khi component re-render
	const filteredLanguages = useMemo(() => {
		return languages.filter(lang => {
			const keyLower = (lang.key || "").toLowerCase();
			const viLower = (lang.vi || "").toLowerCase();
			const enLower = (lang.en || "").toLowerCase();
			const searchLower = search.toLowerCase();
			return (
				keyLower.includes(searchLower) ||
				viLower.includes(searchLower) ||
				enLower.includes(searchLower)
			);
		});
	}, [languages, search]);

	// Xử lý chọn dòng (Ctrl/Shift hoặc click đơn)
	const handleRowClick = (record, index, event) => {
		// Ngăn chặn event bubbling
		event.preventDefault();
		event.stopPropagation();

		// Bỏ qua sự kiện click nếu đang ở chế độ sửa
		if (editingRow !== null) {
			return;
		}

		// Sử dụng tracking biến để chắc chắn chỉ xử lý lần click đầu tiên
		if (event.detail > 1) {
			// Double-click: Bắt đầu sửa inline
			handleRowDoubleClick(record, index);
			return;
		}

		let newSelected = [...selectedRecords];

		if (event.ctrlKey) {
			// Toggle dòng hiện tại
			const exists = newSelected.find(r => r.id === record.id);
			if (exists) {
				newSelected = newSelected.filter(r => r.id !== record.id);
			} else {
				newSelected.push(record);
			}
			setSelectedRecords(newSelected);
			setLastSelectedIndex(index);
		} else if (event.shiftKey && lastSelectedIndex !== null) {
			// Chọn tất cả các dòng từ lastSelectedIndex đến index hiện tại
			const start = Math.min(lastSelectedIndex, index);
			const end = Math.max(lastSelectedIndex, index);
			
			// Tạo mảng mới thay vì thay đổi mảng hiện tại để tránh re-render không cần thiết
			newSelected = [...newSelected];
			
			for (let i = start; i <= end; i++) {
				const rec = filteredLanguages[i];
				if (rec && !newSelected.some(r => r.id === rec.id)) {
					newSelected.push(rec);
				}
			}
			setSelectedRecords(newSelected);
		} else {
			// Click bình thường: chọn duy nhất dòng này
			newSelected = [record];
			setSelectedRecords(newSelected);
			setLastSelectedIndex(index);
		}

		// Cập nhật form: nếu chỉ có 1 dòng được chọn, đưa dữ liệu sang form
		if (newSelected.length === 1) {
			const sel = newSelected[0];
			setFormKey(sel.key || '');
			setFormVi(sel.vi || '');
			setFormEn(sel.en || '');
		} else {
			// Nếu nhiều dòng được chọn, xóa dữ liệu form
			setFormKey('');
			setFormVi('');
			setFormEn('');
		}
	};

	// Xử lý double click để edit inline
	const handleRowDoubleClick = (record, index) => {
		// Set dòng đang sửa và dữ liệu edit
		setEditingRow(record.id);
		setEditData({
			key: record.key || '',
			vi: record.vi || '',
			en: record.en || ''
		});
		
		// Đảm bảo chỉ có 1 dòng được chọn khi sửa
		setSelectedRecords([record]);
		setLastSelectedIndex(index);
	};

	// Cập nhật dữ liệu đang edit
	const handleEditChange = (field, value) => {
		setEditData(prev => ({
			...prev,
			[field]: value
		}));
	};

	// Hoàn thành edit và lưu thay đổi
	const handleEditComplete = () => {
		const editedRecord = selectedRecords[0];
		if (!editedRecord) return cancelEdit();

		// Sử dụng lại API cập nhật language nhưng với dữ liệu từ editData và id từ editedRecord
		languageService.updateLanguage(tool, editedRecord.id, editData.key, editData.vi, editData.en)
			.then(res => {
				if (res.data.success) {
					showNotification('success', 'Cập nhật trực tiếp thành công');
					loadLanguages(tool);
					clearForm(); // Xóa nội dung form bên trái sau khi cập nhật thành công
				} else {
					showNotification('danger', res.data.message || 'Cập nhật thất bại');
				}
			})
			.catch(err => {
				showNotification('danger', `Lỗi: ${err.message}`);
			})
			.finally(() => {
				setEditingRow(null);
			});
	};

	// Hủy edit
	const cancelEdit = () => {
		setEditingRow(null);
		setEditData({ key: '', vi: '', en: '' });
	};

	// Xử lý phím tắt khi đang edit
	const handleEditKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// Enter để hoàn thành
			e.preventDefault();
			handleEditComplete();
		} else if (e.key === 'Escape') {
			// Escape để hủy
			e.preventDefault();
			cancelEdit();
		}
	};

	// Xử lý chuột phải (context menu) - đảo nút Sao chép (xanh) và Đóng (đỏ)
	const handleContextMenu = (record, index, event) => {
		event.preventDefault();
		event.stopPropagation();
		
		// Nếu đang edit, không hiện context menu
		if (editingRow !== null) return;
		
		let currentSelection = selectedRecords;
		// Nếu dòng nhấn chuột phải chưa được chọn, chọn nó
		if (!currentSelection.some(r => r.id === record.id)) {
			currentSelection = [record];
			setSelectedRecords(currentSelection);
			setLastSelectedIndex(index);
		}

		// Tạo nội dung text theo VB.NET cho tất cả các key được chọn
		const content = currentSelection
			.map(r => `Public ${r.key} As String`)
			.join('\n');

		Swal.fire({
			title: 'Xuất key (VB.NET)',
			html: `
		<textarea
		  id="vbnetTextarea"
		  readonly
		  style="
			width:100%;
			height:300px;
			font-family:monospace;
			font-size:12px;
			line-height:1.4;
			background-color:#f9f9f9;
		  "
		>${content}</textarea>
	  `,
			showConfirmButton: true,   // Nút "Sao chép"
			showDenyButton: true,      // Nút "Đóng"
			confirmButtonText: 'Sao chép',
			denyButtonText: 'Đóng',
			confirmButtonColor: '#3085d6', // Xanh
			denyButtonColor: '#d33',      // Đỏ
			focusConfirm: true,           // Focus vào nút Sao chép
			// Sao chép khi bấm nút Confirm, không đóng modal
			preConfirm: () => {
				const textArea = document.getElementById('vbnetTextarea');
				if (textArea) {
					textArea.select();
					document.execCommand('copy');

					showNotification('info', 'Đã sao chép vào clipboard!');
				}
				// return false để không đóng modal
				return false;
			}
			// Nếu bấm nút Đóng (deny), modal sẽ tự đóng theo mặc định
		});
	};

	const clearForm = () => {
		setSelectedRecords([]);
		setLastSelectedIndex(null);
		setFormKey('');
		setFormVi('');
		setFormEn('');
		setJsonInput('');
		setJsonError('');
	};

	// Validate JSON input
	const validateJson = (jsonString) => {
		try {
			const data = JSON.parse(jsonString);
			
			// Kiểm tra xem data có phải là mảng không
			if (!Array.isArray(data)) {
				setJsonError('Dữ liệu phải là một mảng các đối tượng');
				return false;
			}
			
			// Kiểm tra từng item trong mảng
			for (let i = 0; i < data.length; i++) {
				const item = data[i];
				if (!item.key || !item.vi || !item.en) {
					setJsonError(`Dòng ${i + 1}: Thiếu trường bắt buộc (key, vi, en)`);
					return false;
				}
			}
			
			setJsonError('');
			return true;
		} catch (e) {
			setJsonError('Lỗi cú pháp JSON: ' + e.message);
			return false;
		}
	};
	
	// Tạo nhiều bản ghi ngôn ngữ từ JSON
	const createLanguageFromJson = () => {
		if (!jsonInput.trim()) {
			setJsonError('Vui lòng nhập dữ liệu JSON');
			return;
		}
		
		if (validateJson(jsonInput)) {
			const jsonData = JSON.parse(jsonInput);
			
			languageService.createLanguageJson(tool, jsonData)
				.then(res => {
					if (res.data.success) {
						showNotification('success', `Thêm thành công ${jsonData.length} bản ghi ngôn ngữ`);
						loadLanguages(tool);
						clearForm();
					} else {
						showNotification('danger', res.data.message || 'Thêm mới thất bại');
					}
				})
				.catch(err => {
					showNotification('danger', `Lỗi: ${err.message}`);
				});
		}
	};

	const createLanguage = () => {
		languageService.createLanguage(tool, formKey, formVi, formEn)
			.then(res => {
				if (res.data.success) {
					showNotification('success', 'Thêm mới ngôn ngữ thành công');
					loadLanguages(tool);
					clearForm();
				} else {
					showNotification('danger', res.data.message || 'Thêm mới thất bại');
				}
			})
			.catch(err => {
				showNotification('danger', `Lỗi: ${err.message}`);
			});
	};

	const updateLanguage = () => {
		if (selectedRecords.length !== 1) {
			showNotification('warning', 'Vui lòng chọn một bản ghi để cập nhật');
			return;
		}

		const selectedRecord = selectedRecords[0];
		
		languageService.updateLanguage(tool, selectedRecord.id, formKey, formVi, formEn)
			.then(res => {
				if (res.data.success) {
					showNotification('success', 'Cập nhật ngôn ngữ thành công');
					loadLanguages(tool);
					clearForm();
				} else {
					showNotification('danger', res.data.message || 'Cập nhật thất bại');
				}
			})
			.catch(err => {
				showNotification('danger', `Lỗi: ${err.message}`);
			});
	};

	// Xóa theo id và key (dựa vào selectedRecords, nếu có nhiều dòng, xóa từng dòng)
	const deleteLanguage = () => {
		if (selectedRecords.length === 0) return;
		Swal.fire({
			title: 'Xác nhận xóa?',
			text: `Bạn có chắc muốn xóa ${selectedRecords.length} bản ghi?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Có, xóa!',
			cancelButtonText: 'Hủy'
		}).then(result => {
			if (result.isConfirmed) {
				// Gọi API xóa cho từng bản ghi
				Promise.all(
					selectedRecords.map(rec =>
						languageService.deleteLanguage(tool, rec.id, rec.key)
					)
				).then(responses => {
					// Nếu tất cả thành công
					const allSuccess = responses.every(r => r.data.success);
					if (allSuccess) {
						showNotification('success', 'Xóa ngôn ngữ thành công');
					} else {
						showNotification('danger', 'Có lỗi xảy ra khi xóa một số bản ghi');
					}
					loadLanguages(tool);
					clearForm();
				}).catch(err => {
					showNotification('danger', `Lỗi: ${err.message}`);
				});
			}
		});
	};

	const commitLanguage = () => {
		languageService.commitLanguage(tool)
			.then(res => {
				if (res.data.success) {
					showNotification('success', 'Commit ngôn ngữ thành công');
				} else {
					showNotification('danger', res.data.message || 'Commit thất bại');
				}
			})
			.catch(err => {
				showNotification('danger', `Lỗi: ${err.message}`);
			});
	};

	return (
		<div className="content">
			<Row>
				{/* Bên trái: Form thêm/cập nhật ngôn ngữ */}
				<Col xs="12" sm="12" md="12" lg="4">
					<Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
						<CardHeader>
							<Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>
								{selectedRecords.length === 1 ? "Cập nhật ngôn ngữ" : "Thêm mới ngôn ngữ"}
							</Label>
							{selectedRecords.length !== 1 && (
								<Nav tabs className="mt-2">
									<NavItem>
										<NavLink
											className={activeTab === 'form' ? 'active' : ''}
											onClick={() => setActiveTab('form')}
											style={{ cursor: 'pointer' }}
										>
											Form đơn lẻ
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={activeTab === 'json' ? 'active' : ''}
											onClick={() => setActiveTab('json')}
											style={{ cursor: 'pointer' }}
										>
											Nhập JSON
										</NavLink>
									</NavItem>
								</Nav>
							)}
						</CardHeader>
						<CardBody>
							{selectedRecords.length === 1 ? (
								// Form cập nhật ngôn ngữ
								<>
									<FormGroup>
										<Label for="langKey">Key</Label>
										<Input
											id="langKey"
											value={formKey}
											onChange={(e) => setFormKey(e.target.value)}
											placeholder="Nhập key"
											disabled={true}
										/>
									</FormGroup>
									<FormGroup>
										<Label style={{ marginTop: '10px' }} for="langVi">Vietnamese</Label>
										<Input
											type="textarea"
											id="langVi"
											rows="3"
											value={formVi}
											onChange={(e) => setFormVi(e.target.value)}
											placeholder="Nhập nội dung tiếng Việt"
										/>
									</FormGroup>
									<FormGroup>
										<Label style={{ marginTop: '10px' }} for="langEn">English</Label>
										<Input
											type="textarea"
											id="langEn"
											rows="3"
											value={formEn}
											onChange={(e) => setFormEn(e.target.value)}
											placeholder="Nhập nội dung tiếng Anh"
										/>
									</FormGroup>
								</>
							) : (
								// Form thêm mới với 2 tab
								<TabContent activeTab={activeTab}>
									<TabPane tabId="form">
										<FormGroup>
											<Label for="langKey">Key</Label>
											<Input
												id="langKey"
												value={formKey}
												onChange={(e) => setFormKey(e.target.value)}
												placeholder="Nhập key"
											/>
										</FormGroup>
										<FormGroup>
											<Label style={{ marginTop: '10px' }} for="langVi">Vietnamese</Label>
											<Input
												type="textarea"
												id="langVi"
												rows="3"
												value={formVi}
												onChange={(e) => setFormVi(e.target.value)}
												placeholder="Nhập nội dung tiếng Việt"
											/>
										</FormGroup>
										<FormGroup>
											<Label style={{ marginTop: '10px' }} for="langEn">English</Label>
											<Input
												type="textarea"
												id="langEn"
												rows="3"
												value={formEn}
												onChange={(e) => setFormEn(e.target.value)}
												placeholder="Nhập nội dung tiếng Anh"
											/>
										</FormGroup>
									</TabPane>
									<TabPane tabId="json">
										<FormGroup>
											<Label for="jsonInput">Nhập danh sách ngôn ngữ dạng JSON</Label>
											<Input
												type="textarea"
												id="jsonInput"
												rows="15"
												value={jsonInput}
												onChange={(e) => setJsonInput(e.target.value)}
												placeholder={`[
  {
    "key": "googleTranslateFree",
    "vi": "Google Dịch",
    "en": "Google Translate Free"
  },
  {
    "key": "anotherKey",
    "vi": "Giá trị Tiếng Việt",
    "en": "English Value"
  }
]`}
												style={{ 
													fontFamily: 'monospace', 
													fontSize: '12px',
													whiteSpace: 'pre',
													minHeight: "450px",
												}}
											/>
										</FormGroup>
										{jsonError && (
											<Alert color="danger">{jsonError}</Alert>
										)}
									</TabPane>
								</TabContent>
							)}
						</CardBody>
						<CardFooter>
							{selectedRecords.length === 1 ? (
								<>
									<Button color="warning" onClick={updateLanguage}>Cập nhật</Button>
									<Button color="danger" className="ml-2" onClick={deleteLanguage}>Xóa</Button>
									<Button color="secondary" className="ml-2" onClick={clearForm}>Hủy</Button>
								</>
							) : (
								activeTab === 'form' ? (
									<Button color="success" onClick={createLanguage}>Thêm mới</Button>
								) : (
									<Button color="success" onClick={createLanguageFromJson}>Nhập từ JSON</Button>
								)
							)}
						</CardFooter>
					</Card>
				</Col>

				{/* Bên phải: Bảng danh sách ngôn ngữ */}
				<Col xs="12" sm="12" md="12" lg="8">
					<Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
						<CardHeader className="d-flex align-items-center">
							<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
								<DropdownToggle caret className="btn btn-info">
									{toolName}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => { setTool('dhbrender'); setToolName("DHB Render") }} >DHB Render</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbreupyoutube'); setToolName("DHB Reup Youtube") }} >Reup Youtube</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbreupfacebook'); setToolName("DHB Reup Facebook") }} >Reup Facebook</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbreuptiktok'); setToolName("DHB Reup TikTok") }} >Reup TikTok</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbreuptwitter'); setToolName("DHB Reup Twitter") }} >Reup Twitter</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbreupinstagram'); setToolName("DHB Reup Instagram") }} >Reup Instagram</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbstreampro'); setToolName("DHB Stream Pro") }} >Stream Pro</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbgologinmanager'); setToolName("DHB GoLogin Manager") }} >GoLogin Manager</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbstreamfacebook'); setToolName("DHB Stream Facebook") }} >Stream Facebook</DropdownItem>
									<DropdownItem onClick={() => { setTool('dhbyoutubemanager'); setToolName("DHB Youtube Manager") }} >Youtube Manager</DropdownItem>
									{/* Thêm các tool khác nếu cần */}
								</DropdownMenu>
							</Dropdown>
							<Button color="success" className="ml-2" onClick={commitLanguage}>Commit</Button>
							{editingRow !== null && (
								<>
									<Button color="primary" className="ml-2" onClick={handleEditComplete}>Lưu</Button>
									<Button color="secondary" className="ml-2" onClick={cancelEdit}>Hủy</Button>
								</>
							)}
							<Input
								type="text"
								placeholder="Tìm kiếm..."
								value={search}
								onChange={handleSearch}
								className="ml-auto"
								style={{ width: '200px' }}
							/>
						</CardHeader>
						<CardBody>
							{/* Bỏ responsive để không hiển thị scrollbar không cần thiết */}
							<Table hover style={tableStyle}>
								<thead className="text-primary">
									<tr>
										<th style={cellStyle}>Key</th>
										<th style={cellStyle}>Vietnamese</th>
										<th style={cellStyle}>English</th>
									</tr>
								</thead>
								<tbody>
									{filteredLanguages.map((lang, index) => {
										const isSelected = selectedRecords.some(r => r.id === lang.id);
										const isEditing = editingRow === lang.id;
										
										return (
											<tr
												key={lang.id || index}
												style={isSelected ? selectedRowStyle : tableRowStyle}
												className={isSelected ? 'selected-row' : ''}
												onClick={(e) => handleRowClick(lang, index, e)}
												onDoubleClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													handleRowDoubleClick(lang, index);
												}}
												onContextMenu={(e) => handleContextMenu(lang, index, e)}
												data-testid={`language-row-${index}`}
											>
												<td style={cellStyle}>
													{isEditing ? (
														<Input
															type="text"
															value={editData.key}
															onChange={(e) => handleEditChange('key', e.target.value)}
															onKeyDown={handleEditKeyDown}
															style={cellInputStyle}
															autoFocus
														/>
													) : (
														lang.key
													)}
												</td>
												<td style={cellStyle}>
													{isEditing ? (
														<Input
															type="text"
															value={editData.vi}
															onChange={(e) => handleEditChange('vi', e.target.value)}
															onKeyDown={handleEditKeyDown}
															style={cellInputStyle}
														/>
													) : (
														lang.vi
													)}
												</td>
												<td style={cellStyle}>
													{isEditing ? (
														<Input
															type="text"
															value={editData.en}
															onChange={(e) => handleEditChange('en', e.target.value)}
															onKeyDown={handleEditKeyDown}
															style={cellInputStyle}
														/>
													) : (
														lang.en
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</CardBody>
						{editingRow !== null && (
							<CardFooter className="text-center">
								<small className="text-muted">
									Nhấn Enter để lưu, ESC để hủy, hoặc click vào nút Lưu/Hủy
								</small>
							</CardFooter>
						)}
					</Card>
				</Col>
			</Row>

			{/* Thông báo Alert ở góc phải màn hình */}
			{notification.visible && (
				<Alert 
					color={notification.color} 
					style={notificationStyle}
					toggle={onDismissNotification}
					fade={true}
				>
					{notification.message}
				</Alert>
			)}
		</div>
	);
}

export default Language;
