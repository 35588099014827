import React, { useState, useEffect } from 'react'
import {
    Table,
    Input,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Col,
    Row,
    Card,
    CardFooter,
    CardBody,
    Label,
    FormGroup,
    CardHeader,
    CardTitle
} from "reactstrap";
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Select from 'react-select';

import updateService from 'services/update.service'
import toolService from 'services/tool.service'

function Orders() {
    const [lstTool, setLstTool] = useState([])
    const [tool, setTool] = useState('')
    const [toolName, setToolName] = useState('')
    const [plugin, setPlugin] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [id, setId] = useState('')
    const [typeFile, setTypeFile] = useState('Tải lên file')

    const [localPath, setLocalPath] = useState('')

    const [lstFolder, setLstFolder] = useState([])
    const [folderSelected, setFolderSelected] = useState('')

    const [lstFile, setLstFile] = useState([])
    const [fileSelected, setFileSelected] = useState('')

    const [version, setVersion] = useState('file')
    const [log, setLog] = useState('')

    const [selectedFile, setSelectedFile] = useState('')

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        toolService.getToolDatabase().then((result) => {
            setLstTool(result.data)

            loadPlugin(result.data[0].matool)
            setToolName(result.data[0].name)
        })
    }, [])

    function loadPlugin(tool) {
        setTool(tool)

        updateService.getPlugin(tool).then((result) => {
            setPlugin(result.data.data)
        })
    }

    function postPlugin() {
        let file = selectedFile;
        if (typeFile === "Chọn từ server") {
            file = folderSelected + "/" + fileSelected;
            setSelectedFile(file);
        }

        // Hiển thị Swal với 2 thanh progress, hiển thị % ở giữa và có nút Cancel
        Swal.fire({
            title: 'Đang upload plugin...',
            // HTML kèm style CSS
            html: `
            <style>
              .progress-wrapper {
                position: relative;
                width: 100%;
                height: 25px;
                background-color: #eee;
                margin: 10px 0;
                border-radius: 5px;
              }
              .progress-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: #007bff;
                text-align: center;
                color: #fff;
                line-height: 25px;
                width: 0%;
                transition: width 0.4s ease;
              }
              .cancel-button {
                margin-top: 10px;
                padding: 6px 15px;
                background-color: #dc3545;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;
              }
              .cancel-button:hover {
                background-color: #c82333;
              }
            </style>
            <div>
              <div style="margin-top:10px;">Server VN (ViettelIDC)</div>
              <div class="progress-wrapper">
                <div id="progressBar1" class="progress-bar">0%</div>
              </div>
              
              <div style="margin-top:20px;margin-bottom:10px;">Server SG (Digital Ocean)</div>
              <div class="progress-wrapper">
                <div id="progressBar2" class="progress-bar">0%</div>
              </div>
    
            </div>
            `,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        // Gọi service upload đồng thời đến 2 server
        updateService.postPlugin(
            tool,
            typeFile,
            localPath,
            file,
            // Callback tiến trình upload tới Server 1 (VN)
            (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const bar1 = Swal.getPopup().querySelector('#progressBar1');
                if (bar1) {
                    bar1.style.width = percentCompleted + '%';
                    bar1.textContent = percentCompleted + '%';
                }
            },
            // Callback tiến trình upload tới Server 2 (SG)
            (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const bar2 = Swal.getPopup().querySelector('#progressBar2');
                if (bar2) {
                    bar2.style.width = percentCompleted + '%';
                    bar2.textContent = percentCompleted + '%';
                }
            }
        )
            .then((responses) => {
                // Xử lý kết quả upload từ 2 server
                const forwardResponse = responses[0];
                const currentResponse = responses[1];
                const success = forwardResponse.data.success && currentResponse.data.success;

                Swal.fire({
                    icon: success ? 'success' : 'error',
                    title: success ? 'Upload thành công' : 'Upload thất bại',
                    text: `\nServer VN (ViettelIDC): tải lên ${forwardResponse.data.success ? 'thành công' : 'thất bại'}\n\nServer SG (Digital Ocean): tải lên ${currentResponse.data.success ? 'thành công' : 'thất bại'}`,
                    showConfirmButton: true,
                    timer: 3000
                });

                // Nếu thành công, load lại danh sách plugin
                if (success) {
                    updateService.getPlugin(tool).then((result) => {
                        setPlugin(result.data.data);
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Upload thất bại',
                    text: error.message,
                });
            });
    }



    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Đường dẫn máy chủ</th>
                        <th className="table-device-mobile">Đường dẫn người dùng</th>
                        <th className="table-device-mobile">Kích thước</th>
                        <th className="table-device-mobile">Cập nhập lúc</th>
                        <th className='text-center'>Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }


    function loadFillData() {
        return plugin.map((data, key) => {
            return (
                <tr key={key}>
                    <td className="table-device-mobile">{data.url_v2}</td>
                    <td className="table-device-mobile">{data.path}</td>
                    <td className="table-device-mobile">{data.filesize}</td>
                    <td className="table-device-mobile">{moment(data.createdAt).format('DD/MM/yyyy HH:mm')}</td>

                    <td className='text-center'>
                        <Button style={{ marginLeft: "10px" }} type="button" className="btn btn-danger " onClick={() => {

                            Swal.fire({
                                title: 'Xác nhận xóa ?',
                                text: `Điều này sẽ xóa bản ghi ${data.path} phần mềm ${toolName}`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Có, xóa bản ghi!'
                            }).then((result) => {
                                if (result.isConfirmed) {

                                    updateService.deletePlugin(data.tool, data.id, data.url_v2).then((result) => {
                                        if (result.data.success) {
                                            updateService.getPlugin(data.tool).then((result) => {
                                                setPlugin(result.data.data)
                                            })
                                        }

                                        Swal.fire({
                                            icon: result.data.success ? 'success' : 'error',
                                            title: result.data.message,
                                            showConfirmButton: true,
                                            timer: 1000
                                        })
                                    })
                                }
                            })
                        }}>Xóa</Button>
                    </td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            <Row>
                <Col md="9">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                        <CardHeader>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                <DropdownToggle className="btn-info" caret>{toolName}</DropdownToggle>
                                <DropdownMenu >
                                    {
                                        lstTool.map((data, key) => {
                                            return (
                                                <DropdownItem onClick={() => { loadPlugin(data.matool); setToolName(data.name) }} key={key}>{data.name}</DropdownItem>
                                            )
                                        })
                                    }
                                    <DropdownItem onClick={() => { loadPlugin('shared'); setToolName("Shared") }} >Shared</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardHeader>
                        <CardBody>
                            {table()}
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="3" md="4">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Thêm bảng ghi</Label>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label>Nguồn dữ liệu</Label>
                                <Select
                                    placeholder={typeFile}
                                    value={typeFile}
                                    onChange={(e) => {
                                        setTypeFile(e.value)

                                        if (e.value == "Chọn từ server") {
                                            updateService.getExplorerFiles("").then((result) => {
                                                var result = result.data.data
                                                var lst = []
                                                for (let i = 0; i < result.length; i++) {
                                                    lst.push({ value: result[i], label: result[i] });
                                                }

                                                setLstFolder(lst)
                                                setFolderSelected("./update/shared/plugin")

                                                updateService.getExplorerFiles("./update/shared/plugin").then((result) => {
                                                    var result = result.data.data
                                                    var lst = []
                                                    for (let i = 0; i < result.length; i++) {
                                                        lst.push({ value: result[i], label: result[i] });
                                                    }

                                                    setLstFile(lst)
                                                    setFileSelected("")
                                                })
                                            })
                                        }
                                    }}
                                    options={
                                        [
                                            { value: 'Tải lên file', label: 'Tải lên file' },
                                            { value: 'Chọn từ server', label: 'Chọn từ server' }
                                        ]
                                    }
                                />

                                {
                                    typeFile == 'Tải lên file'
                                        ?
                                        <>
                                            <Label style={{ marginTop: "1rem" }}>Đường dẫn người dùng</Label>
                                            <Input style={{ maxHeight: "100%" }}
                                                value={localPath}
                                                id="localPath"
                                                onChange={(e) => { setLocalPath(e.target.value) }}
                                            />

                                            <Label style={{ marginTop: "1rem" }}>Tệp tin</Label>
                                            <Input
                                                type="file"
                                                id="file"
                                                onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                            />
                                        </>
                                        :
                                        <>
                                            <div style={{ paddingTop: "15px" }}>
                                                <Label>Thư mục</Label>
                                                <Select
                                                    placeholder={folderSelected}
                                                    value={folderSelected}
                                                    onChange={(e) => {
                                                        setFolderSelected(e.value)

                                                        updateService.getExplorerFiles(e.value).then((result) => {
                                                            var result = result.data.data
                                                            var lst = []
                                                            for (let i = 0; i < result.length; i++) {
                                                                lst.push({ value: result[i], label: result[i] });
                                                            }

                                                            setLstFile(lst)
                                                            setFileSelected("")
                                                        })
                                                    }}
                                                    options={lstFolder}
                                                />
                                            </div>

                                            <div style={{ paddingTop: "15px" }}>
                                                <Label>Tệp tin</Label>
                                                <Select
                                                    placeholder={fileSelected}
                                                    value={fileSelected}
                                                    onChange={(e) => {
                                                        setFileSelected(e.value)
                                                    }}
                                                    options={lstFile}
                                                />
                                            </div>

                                            <Label style={{ marginTop: "1rem" }}>Local Path</Label>
                                            <Input style={{ maxHeight: "100%" }}
                                                value={localPath}
                                                id="localPath"
                                                onChange={(e) => { setLocalPath(e.target.value) }}
                                            />
                                        </>
                                }
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <Button
                                style={{ width: "100%" }}
                                type="button"
                                className="btn btn-success"
                                onClick={postPlugin}
                            >
                                Thêm mới
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default Orders