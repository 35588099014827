import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
    Col,
    Row,
    Button,
    Input,
    FormGroup,
    Label
} from "reactstrap";
import MembersService from "../services/staff.service"
import DHBCreateMember from "../components/Childrens/DHBCreateMembers"
import DHBMemberDetails from "../components/Childrens/DHBMemberDetails"
import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function Staff() {
    const [listMembers, setListMembers] = useState([])
    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])
    const [selectFilter, setSelectFilter] = useState("TÀI KHOẢN")
    const [filter, setFilter] = useState("")
    const [listDataFilter, setListDataFilter] = useState([])

    function optionsFilter() {
        return [
            { value: 'ID', label: 'ID' },
            { value: 'TÀI KHOẢN', label: 'TÀI KHOẢN' },
            { value: 'EMAIL', label: 'EMAIL' },
            { value: 'HỌ TÊN', label: 'HỌ TÊN' },
        ]
    }

    useEffect(() => {
        MembersService.getMembers().then((result) => {
            setListMembers(result.data)
            setListDataFilter(result.data)
        })
    }, [])

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">ID</th>
                        <th>Tài khoản</th>
                        <th className="table-device-mobile">Email</th>
                        <th>Họ tên</th>
                        <th>Quyền</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function toDetailView(val) {
        setPage('detail')
        setDataProps(val)
    }

    function loadFillData() {
        return listMembers.map((data, key) => {
            return (
                <tr onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <th className="table-device-mobile">{data.id}</th>
                    <td>{data.username}</td>
                    <td className="table-device-mobile">{data.email}</td>
                    <td>{data.fullname}</td>
                    <td>{data.role}</td>
                </tr>
            )
        })
    };

    function back() {
        return (
            <Button
                color="secondary"
                style={{ marginBottom: "15px", fontWeight: "bold", width: "120px" }}
                onClick={() => setPage('')}>
                Quay lại
            </Button>
        )
    }

    function filterr() {
        let result
        if (selectFilter == "TÀI KHOẢN") {
            result = listDataFilter.filter(word => serach(word.username, filter));
        } else if (selectFilter == "EMAIL") {
            result = listDataFilter.filter(word => serach(word.email, filter));
        } else if (selectFilter == "HỌ TÊN") {
            result = listDataFilter.filter(word => serach(word.fullname, filter));
        } else if (selectFilter == "ID") {
            result = listDataFilter.filter(word => serach(word.id.toString(), filter));
        }
        setListMembers(result)
    }

    function serach(value, key) {
        try {
            return value.toLowerCase().search(key.toLowerCase()) != -1 ? true : false
        } catch (error) {
            return false
        }
    }

    return (
        <div className="content">
            {
                page == 'create'
                    ?
                    <>
                        {back()}
                        <DHBCreateMember />
                    </>
                    :
                    page == "detail"
                        ?
                        <>
                            {back()}
                            <DHBMemberDetails data={dataProps} />
                        </>
                        :
                        <>
                            <Row>
                                <Col md="12">
                                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                                        <CardHeader>
                                            <CardTitle tag="h4">Quản lý nhân viên</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row className="mb-3">
                                                <Col md="6">
                                                    <Button
                                                        color="primary"
                                                        style={{ width: "180px", height: "38px" }}
                                                        onClick={() => setPage('create')}>
                                                        Thêm nhân viên mới
                                                    </Button>
                                                </Col>
                                                <Col md="6">
                                                    <Row>
                                                        <Col md="5">
                                                            <FormGroup style={{ marginBottom: "0" }}>
                                                                <Select
                                                                    placeholder={selectFilter}
                                                                    value={{ value: selectFilter, label: selectFilter }}
                                                                    onChange={(e) =>
                                                                        setSelectFilter(e.value)
                                                                    }
                                                                    options={optionsFilter()}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="5">
                                                            <FormGroup style={{ marginBottom: "0" }}>
                                                                <Input
                                                                    placeholder="Nhập từ khóa tìm kiếm..."
                                                                    value={filter}
                                                                    onChange={(e) =>
                                                                        setFilter(e.target.value)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <Button
                                                                color="primary"
                                                                style={{ width: "100%", height: "38px" }}
                                                                onClick={() => filterr()}>
                                                                Tìm
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {table()}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
            }
        </div>
    )
}

export default Staff