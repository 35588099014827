// src/views/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Label, FormGroup, CardHeader, Progress, CardFooter } from 'reactstrap';
import dashboardService from 'services/dashboard.service';

const Dashboard = () => {
    // State cho thông tin server (info card) chứa data, requestTime và lastRequest
    const [serverApi1Status, setServerApi1Status] = useState(null);
    const [serverApi2Status, setServerApi2Status] = useState(null);
    const [serverService1Status, setServerService1Status] = useState(null);
    const [serverService2Status, setServerService2Status] = useState(null);
    const [serverDownloadStatus, setServerDownloadStatus] = useState(null);

    // Khoảng thời gian chờ giữa 2 request (ms)
    const refreshInterval = 1000;

    // Hàm format thời gian request gần nhất (ví dụ: 1s trước hoặc 500ms trước)
    const getTimeAgo = (lastRequest) => {
        if (!lastRequest) return '';
        const diff = Date.now() - new Date(lastRequest).getTime();
        if (diff < 1000) return `1s ago`;
        const seconds = Math.floor(diff / 1000);
        return `${seconds}s ago`;
    };

    // Mỗi hàm polling đo thời gian request và lưu kết quả vào state

    const pollServerAPI1 = () => {
        const start = Date.now();
        dashboardService.getServerAPI_1()
            .then(res => {
                const end = Date.now();
                setServerApi1Status({
                    data: res.data,
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .catch(err => {
                console.error("Server API 1 error:", err);
                const end = Date.now();
                setServerApi1Status({
                    data: {
                        serviceStatus: 'Down',
                        memory: { usagePercent: '0', total: '0', used: '0' },
                        cpu: { usagePerCore: [] },
                        disk: { usagePercent: '0', size: '0', used: '0' },
                        slave: null,
                    },
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .finally(() => {
                setTimeout(pollServerAPI1, refreshInterval);
            });
    };

    const pollServerAPI2 = () => {
        const start = Date.now();
        dashboardService.getServerAPI_2()
            .then(res => {
                const end = Date.now();
                setServerApi2Status({
                    data: res.data,
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .catch(err => {
                console.error("Server API 2 error:", err);
                const end = Date.now();
                setServerApi2Status({
                    data: {
                        serviceStatus: 'Down',
                        memory: { usagePercent: '0', total: '0', used: '0' },
                        cpu: { usagePerCore: [] },
                        disk: { usagePercent: '0', size: '0', used: '0' },
                        slave: null,
                    },
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .finally(() => {
                setTimeout(pollServerAPI2, refreshInterval);
            });
    };

    const pollServerService1 = () => {
        const start = Date.now();
        dashboardService.getServerService_1()
            .then(res => {
                const end = Date.now();
                setServerService1Status({
                    data: res.data,
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .catch(err => {
                console.error("Server Service 1 error:", err);
                const end = Date.now();
                setServerService1Status({
                    data: {
                        serviceStatus: 'Down',
                        memory: { usagePercent: '0', total: '0', used: '0' },
                        cpu: { usagePerCore: [] },
                        disk: { usagePercent: '0', size: '0', used: '0' },
                        slave: null,
                    },
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .finally(() => {
                setTimeout(pollServerService1, refreshInterval);
            });
    };

    const pollServerService2 = () => {
        const start = Date.now();
        dashboardService.getServerService_2()
            .then(res => {
                const end = Date.now();
                setServerService2Status({
                    data: res.data,
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .catch(err => {
                console.error("Server Service 2 error:", err);
                const end = Date.now();
                setServerService2Status({
                    data: {
                        serviceStatus: 'Down',
                        memory: { usagePercent: '0', total: '0', used: '0' },
                        cpu: { usagePerCore: [] },
                        disk: { usagePercent: '0', size: '0', used: '0' },
                        slave: null,
                    },
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .finally(() => {
                setTimeout(pollServerService2, refreshInterval);
            });
    };

    const pollServerDownload = () => {
        const start = Date.now();
        dashboardService.getServerDownload()
            .then(res => {
                const end = Date.now();
                setServerDownloadStatus({
                    data: res.data,
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .catch(err => {
                console.error("Server Download error:", err);
                const end = Date.now();
                setServerDownloadStatus({
                    data: {
                        serviceStatus: 'Down',
                        memory: { usagePercent: '0', total: '0', used: '0' },
                        cpu: { usagePerCore: [] },
                        disk: { usagePercent: '0', size: '0', used: '0' },
                        slave: null,
                    },
                    requestTime: end - start,
                    lastRequest: new Date()
                });
            })
            .finally(() => {
                setTimeout(pollServerDownload, refreshInterval);
            });
    };

    useEffect(() => {
        pollServerAPI1();
        pollServerAPI2();
        pollServerService1();
        pollServerService2();
        pollServerDownload();
    }, []);

    // Hàm kiểm tra màu cho dấu chấm và text dựa trên serviceStatus
    const getStatusColor = (status) => {
        if (status && (status.toLowerCase() === 'live' || status.toLowerCase() === 'yes')) {
            return 'green';
        }
        return 'red';
    };

    // Hàm render card thông tin server, sử dụng đối tượng mặc định khi chưa có dữ liệu
    const renderServerCard = (cardInfo, title, location) => {
        const safeData = (cardInfo && cardInfo.data) || {
            serviceStatus: 'Down',
            memory: { usagePercent: '0', total: '0', used: '0' },
            cpu: { usagePerCore: [] },
            disk: { usagePercent: '0', size: '0', used: '0' },
            slave: null,
        };

        const requestTime = cardInfo?.requestTime || 0;
        const lastRequest = cardInfo?.lastRequest;

        const statusColor = getStatusColor(safeData.serviceStatus);
        const slaveStatus = safeData.slave ? getStatusColor(safeData.slave.Slave_SQL_Running) : 'red';

        return (
            <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px', marginBottom: '20px' }}>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Label style={{ fontSize: '22px', color: 'DimGray', fontWeight: 'bold' }}>
                        {title}<br />{location}
                    </Label>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '18px',
                                height: '18px',
                                borderRadius: '50%',
                                backgroundColor: statusColor
                            }}
                        ></span>
                        <span style={{ fontSize: '16px', color: statusColor, fontWeight: 'bold' }}>
                            {safeData.serviceStatus}: {getTimeAgo(lastRequest)}
                        </span>
                    </div>
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={2} style={{ fontSize: '14px', color: 'DimGray', fontWeight: 'bold' }}>
                            Ram
                        </Label>
                        <Col sm={10}>
                            <Label style={{ fontSize: '14px', color: statusColor }}>
                                Usage: {safeData.memory.usagePercent}<br />
                                Total: {safeData.memory.total} | Used: {safeData.memory.used}
                            </Label>
                            <Progress
                                value={parseFloat(safeData.memory.usagePercent)}
                                color={statusColor}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row style={{ marginTop: '20px' }}>
                        <Label sm={2} style={{ fontSize: '14px', color: 'DimGray', fontWeight: 'bold' }}>
                            CPU
                        </Label>
                        <Col sm={10}>
                            {safeData.cpu.usagePerCore && safeData.cpu.usagePerCore.map((usage, index) => (
                                <div key={index}>
                                    <Label style={{ fontSize: '14px', color: statusColor, marginTop: '5px' }} >
                                        CPU {index + 1} Usage: {usage}%
                                    </Label>
                                    <Progress
                                        value={parseFloat(usage)}
                                        color={statusColor}
                                    />
                                </div>
                            ))}
                        </Col>
                    </FormGroup>

                    <FormGroup row style={{ marginTop: '20px' }}>
                        <Label sm={2} style={{ fontSize: '14px', color: 'DimGray', fontWeight: 'bold' }}>
                            Disk
                        </Label>
                        <Col sm={10}>
                            <Label style={{ fontSize: '14px', color: statusColor }}>
                                Usage: {safeData.disk.usagePercent}<br />
                                Total: {safeData.disk.size} | Used: {safeData.disk.used}
                            </Label>
                            <Progress
                                value={parseFloat(safeData.disk.usagePercent)}
                                color={statusColor}
                                style={{ marginTop: '5px' }}
                            />
                        </Col>
                    </FormGroup>

                    {safeData.slave && (
                        <FormGroup row style={{ marginTop: '20px' }}>
                            <Label sm={2} style={{ fontSize: '14px', color: 'DimGray', fontWeight: 'bold' }}>
                                Slave SQL
                            </Label>
                            <Col sm={10}>
                                <Label style={{ fontSize: '14px' }}>
                                    Master Host: {safeData.slave.masterHost} / api.dhbtools.com
                                </Label>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '12px',
                                            height: '12px',
                                            borderRadius: '50%',
                                            backgroundColor: slaveStatus
                                        }}
                                    ></span>
                                    <span style={{ fontSize: '14px', color: slaveStatus, fontWeight: 'bold' }}>
                                        Slave IO Running : {safeData.slave.Slave_IO_Running}
                                    </span>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '12px',
                                            height: '12px',
                                            borderRadius: '50%',
                                            backgroundColor: slaveStatus
                                        }}
                                    ></span>
                                    <span style={{ fontSize: '14px', color: slaveStatus, fontWeight: 'bold' }}>
                                        Slave SQL Running : {safeData.slave.Slave_SQL_Running}
                                    </span>
                                </div>

                                <Label style={{ fontSize: '12px' }}>
                                    {safeData.slave.Slave_SQL_Running_State}
                                </Label>
                            </Col>
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
        );
    };

    return (
        <div className="content">
            <Row>
                <Col md="4">
                    {renderServerCard(serverApi1Status, "api.dhbtools.com", "SG - Digital Ocean")}
                </Col>
                <Col md="4">
                    {renderServerCard(serverApi2Status, "api2.dhbtools.com", "VN - Viettel IDC")}
                </Col>
                <Col md="4">
                    {renderServerCard(serverDownloadStatus, "download.dhbtools.com", "VN - Home NUC")}
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    {renderServerCard(serverService1Status, "service.dhbtools.com", "SG - Digital Ocean")}
                </Col>
                <Col md="4">
                    {renderServerCard(serverService2Status, "service2.dhbtools.com", "VN - Clouding VPS")}
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;
