// Report.js
import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    CustomInput,
    Label
} from 'reactstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import downloadService from 'services/download.service';

function DownloadTool() {
    const [tools, setTools] = useState([]);
    const [loading, setLoading] = useState(false);

    // Base URL của folder chứa file (theo cấu hình server)
    const baseURL = "https://download.dhbtools.com/download/";

    // Hàm format ngày theo định dạng dd/mm/yyyy HH:mm:ss
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const HH = String(date.getHours()).padStart(2, '0');
        const MM = String(date.getMinutes()).padStart(2, '0');
        const SS = String(date.getSeconds()).padStart(2, '0');
        return `${dd}/${mm}/${yyyy} ${HH}:${MM}:${SS}`;
    };

    useEffect(() => {
        loadTools();
    }, []);

    function loadTools() {
        setLoading(true);
        downloadService
            .getAllTool()
            .then((result) => {
                setTools(result);
            })
            .catch((error) => {
                Swal.fire('Lỗi!', `Không thể tải dữ liệu <br><br> ${error}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Hàm thay đổi trạng thái xác thực
    const handleSwitchChange = (tool) => {
        const newValue = !tool.downloadRequireAuth;
        downloadService
            .updateRequireAuthen(tool.id, { downloadRequireAuth: newValue })
            .then(() => {
                setTools((prevTools) =>
                    prevTools.map((t) =>
                        t.id === tool.id ? { ...t, downloadRequireAuth: newValue } : t
                    )
                );
            })
            .catch((error) => {
                console.error('Lỗi khi cập nhật xác thực:', error);
                Swal.fire('Lỗi!', `Cập nhật xác thực thất bại: ${error}`, 'error');
            });
    };

    const handleCopyUrl = async (tool) => {
        try {
            // Lấy token từ API
            const response = await downloadService.getToken(tool.matool);
            const token = response.token;

            // Tạo URL đầy đủ với token
            const fileUrl = `${baseURL}${tool.matool}?token=${token}`;

            // Hiển thị Swal với TextInput hiển thị URL
            Swal.fire({
                title: 'Sao chép URL',
                html: `
                     <div margin-top: 10px;>
                        <textarea id="file-url" readonly style="width: 100%; height: 150px; padding: 10px; font-size: 14px; resize: none;">${fileUrl}</textarea>
                        <p style="margin-bottom: 10px; margin-top: 10px;">Lưu ý: URL có hiệu lực 1 giờ sau khi tạo</p>
                        <button id="copy-url-btn" style="margin-top: 10px; padding: 5px 15px; background-color: #28a745; color: white; border: none; border-radius: 5px; cursor: pointer;">
                            Sao chép
                        </button>

                    </div>
                `,
                showConfirmButton: false,
                didOpen: () => {
                    // Gắn sự kiện click cho nút "Sao chép" khi Swal đã render
                    const copyButton = Swal.getHtmlContainer()?.querySelector('#copy-url-btn');
                    if (copyButton) {
                        copyButton.addEventListener('click', async () => {
                            try {
                                await navigator.clipboard.writeText(fileUrl);
                                Swal.fire('Thành công!', 'URL đã được sao chép vào clipboard.', 'success');
                            } catch (error) {
                                console.error('Lỗi sao chép URL:', error);
                                Swal.fire('Lỗi!', 'Không thể sao chép URL.', 'error');
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.error('Lỗi sao chép URL:', error);
            Swal.fire('Lỗi!', 'Không thể lấy URL.', 'error');
        }
    };



    // Tham chiếu đến input file cho từng hàng
    const fileInputRefs = useRef({});

    // Khi bấm "Tải lên", kích hoạt input file ẩn của tool đó
    const handleUploadClick = (toolId) => {
        if (fileInputRefs.current[toolId]) {
            fileInputRefs.current[toolId].click();
        }
    };

    // // Khi người dùng chọn file để tải lên
    // const handleFileChange = (tool) => (event) => {
    //     const file = event.target.files[0];
    //     if (!file) return;

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     // Hiển thị modal Swal với thanh tiến trình upload
    //     Swal.fire({
    //         title: 'Đang tải lên...',
    //         html: `
    //     <div id="progress-container" style="width:100%;background: #ddd;">
    //       <div id="progress-bar" style="width:0%;height:20px;background: #28a745;"></div>
    //     </div>
    //     <div id="progress-percent" style="margin-top:10px;">0%</div>
    //   `,
    //         allowOutsideClick: false,
    //         showConfirmButton: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });

    //     // Gọi API upload file với onUploadProgress để cập nhật %
    //     downloadService
    //         .updateToolFile(tool.id, formData, {
    //             onUploadProgress: (progressEvent) => {
    //                 const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //                 const progressBar = Swal.getHtmlContainer()?.querySelector('#progress-bar');
    //                 const progressPercent = Swal.getHtmlContainer()?.querySelector('#progress-percent');
    //                 if (progressBar) {
    //                     progressBar.style.width = percentCompleted + '%';
    //                 }
    //                 if (progressPercent) {
    //                     progressPercent.innerText = percentCompleted + '%';
    //                 }
    //             }
    //         })
    //         .then(() => {
    //             Swal.fire('Thành công!', 'Tải file lên thành công!', 'success');
    //             loadTools();
    //         })
    //         .catch((error) => {
    //             console.error('Lỗi khi tải file:', error);
    //             Swal.fire('Lỗi!', `Tải file thất bại: ${error}`, 'error');
    //         });
    // };

    // Khi người dùng chọn file để tải lên
    const handleFileChange = (tool) => async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        Swal.fire({
            title: 'Đang tải lên...',
            html: `
        <div id="progress-container" style="width:100%;background: #ddd;">
          <div id="progress-bar" style="width:0%;height:20px;background: #28a745;"></div>
        </div>
        <div id="progress-percent" style="margin-top:10px;">0%</div>
      `,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            await downloadService.uploadFileChunked(tool.id, file, (progress) => {
                const progressBar = Swal.getHtmlContainer()?.querySelector('#progress-bar');
                const progressPercent = Swal.getHtmlContainer()?.querySelector('#progress-percent');
                if (progressBar) {
                    progressBar.style.width = progress.progress + '%';
                }
                if (progressPercent) {
                    progressPercent.innerText = progress.progress + '%';
                }
            });

            Swal.fire('Thành công!', 'Tải file lên thành công!', 'success');
            loadTools();
        } catch (error) {
            console.error('Lỗi khi tải file:', error);
            Swal.fire('Lỗi!', `Tải file thất bại: ${error}`, 'error');
        }
    };

    return (
        <div className="content">
            <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
                <CardHeader>
                    <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Phần mềm</Label>
                </CardHeader>
                <CardBody style={{ marginTop: '-15px' }}>
                    <Table className="table-hover" style={{ marginTop: '10px', lineHeight: '15px' }}>
                        <thead className="text-primary">
                            <tr>
                                <th>Phần mềm</th>
                                <th>Tệp tin</th>
                                <th>Mật khẩu giải nén</th>
                                <th>Người tải lên</th>
                                <th>Ngày tải lên</th>
                                <th>Xác thực</th>
                                <th>Sao chép tạm</th>
                                <th>Tải lên</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tools.map((tool, index) => (
                                <tr key={tool.id || index}>
                                    <td><b>{tool.name}</b></td>
                                    <td>{tool.file}</td>
                                    <td>{tool.passunrar}</td>
                                    <td>{tool.uploadedBy}</td>
                                    <td>{formatDate(tool.updatedAt)}</td>
                                    <td>
                                        <CustomInput
                                            type="switch"
                                            id={`switch-${tool.id}`}
                                            checked={!!tool.downloadRequireAuth}
                                            onChange={() => handleSwitchChange(tool)}
                                        />
                                    </td>
                                    <td>
                                        <Button color="info" size="sm" onClick={() => handleCopyUrl(tool)}>
                                            Sao chép URL
                                        </Button>
                                    </td>
                                    <td>
                                        {/* Input file ẩn cho mỗi tool */}
                                        <input
                                            type="file"
                                            ref={(ref) => (fileInputRefs.current[tool.id] = ref)}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange(tool)}
                                        />
                                        <Button color="success" size="sm" onClick={() => handleUploadClick(tool.id)}>
                                            Tải lên
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}

export default DownloadTool;
